import {
  IMAGE_SCALING,
  IMAGE_RATIO,
  BUTTONS_STYLE,
  LIST_IMAGE_LAYOUT,
  LIST_LAYOUT,
  LIST_LOCATION_AND_DATE_FORMAT,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'
import stylesParams, {WidgetStyleParams} from './stylesParams'

export const customCssVars = ({styleParams, isMobile: mobile}: {styleParams: WidgetStyleParams; isMobile: boolean}) => {
  // const defaults = defaultStyleParams(styleParams.booleans.__IS_RTL__)

  const styles = {
    get: param => {
      return (
        styleParams.booleans[param.key] ??
        styleParams.numbers[param.key] ??
        styleParams.colors[param.key] ??
        styleParams.fonts[param.key]
      )
    },
  }

  const get = param => {
    const key = param.key
    const value = styles.get(param)
    const mobileKey = key + 'Mobile'
    const deprecatedMobileValue = styles.get(mobileKey)

    if (mobile) {
      return value ?? deprecatedMobileValue
    }

    return value
  }

  const getListLayout = () => {
    const listLayout = get(stylesParams.listLayout)
    return listLayout === LIST_LAYOUT.GRID ? LIST_LAYOUT.NEW_GRID : listLayout
  }

  const getWidgetLayout = () =>
    get(stylesParams.widgetType) === WIDGET_TYPE.LIST ? getListLayout() : get(stylesParams.widgetLayout)

  const isStatusRibbonEnabled = () => get(stylesParams.showRibbon)

  const isSoldOutRibbonEnabled = () => get(stylesParams.showSoldOutRibbon) ?? isStatusRibbonEnabled()

  const isJoinWaitlistRibbonEnabled = () => get(stylesParams.showJoinWaitlistRibbon) ?? isStatusRibbonEnabled()

  const isRsvpClosedRibbonEnabled = () => get(stylesParams.showRsvpClosedRibbon) ?? isStatusRibbonEnabled()

  const isRecurringRibbonEnabled = () => get(stylesParams.showRecurringRibbon)

  const isOfferRibbonEnabled = () => get(stylesParams.showOfferRibbon)

  const isAnyRibbonEnabled = () =>
    isSoldOutRibbonEnabled() ||
    isJoinWaitlistRibbonEnabled() ||
    isRsvpClosedRibbonEnabled() ||
    isRecurringRibbonEnabled() ||
    isOfferRibbonEnabled()

  const isHollowButton = () =>
    [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(get(stylesParams.buttonsStyle))

  const getButtonsFontKey = () => {
    if (isHollowButton()) {
      return 'hollowButtonsFont'
    } else {
      return 'fullButtonsFont'
    }
  }

  const isListAdditionalInfoEnabled = () => get(stylesParams.listShowAdditionalInfo)

  const isListFullDateEnabled = () => get(stylesParams.listShowFullDate)

  const isListLocationEnabled = () => get(stylesParams.listShowLocation)

  const isListDescriptionEnabled = () => get(stylesParams.listShowDescription)

  const isListSocialShareEnabled = () => get(stylesParams.listShowSocialShare)

  const isListMembersElementEnabled = () => get(stylesParams.listShowMembers)

  const isListAdditionalComponentsVisible = () =>
    isListAdditionalInfoEnabled() &&
    (isListFullDateEnabled() ||
      isListLocationEnabled() ||
      isListDescriptionEnabled() ||
      isListSocialShareEnabled() ||
      isListMembersElementEnabled())

  const isListDateEnabled = () => get(stylesParams.listShowDate)

  const isListVenueNameEnabled = () => get(stylesParams.listShowVenueName)

  const isListDateOrVenueEnabled = () => isListDateEnabled() || isListVenueNameEnabled()

  const isListFullDateOrLocationEnabled = () => isListFullDateEnabled() || isListLocationEnabled()

  const getListLocationAndDateFormat = () => get(stylesParams.listLocationAndDateFormat)

  const isListCompactLocationAndDateFormat = () =>
    getListLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.COMPACT

  const isSideBySideDateOrLocationEnabled = () => {
    const isCompact = isListCompactLocationAndDateFormat()
    return (isCompact && isListDateOrVenueEnabled()) || (!isCompact && isListFullDateOrLocationEnabled())
  }

  const isRoundedButton = () =>
    [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(get(stylesParams.buttonsStyle))

  const isAnyStatusRibbonEnabled = () =>
    isSoldOutRibbonEnabled() || isJoinWaitlistRibbonEnabled() || isRsvpClosedRibbonEnabled()

  const isListMainTitleEnabled = () => get(stylesParams.listShowMainTitle)

  const isListHollowButton = () =>
    [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(get(stylesParams.listButtonStyle))

  const isListRoundedButton = () =>
    [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(get(stylesParams.listButtonStyle))

  const getWidgetType = () => get(stylesParams.widgetType)

  const isMultiEventWidget = () => getWidgetType() === WIDGET_TYPE.LIST

  const isCalendarLayout = () => isMultiEventWidget() && getListLayout() === LIST_LAYOUT.CALENDAR

  const isTodayButtonHollow = () =>
    [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(get(stylesParams.todayButtonStyle))

  const isTodayButtonRounded = () =>
    [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(get(stylesParams.todayButtonStyle))

  const isSideBySideLayout = () => isMultiEventWidget() && getListLayout() === LIST_LAYOUT.SIDE_BY_SIDE

  const isCardsLayout = () =>
    isMultiEventWidget() && (getListLayout() === LIST_LAYOUT.NEW_GRID || getListLayout() === LIST_LAYOUT.GRID)

  const isListLayout = () => isMultiEventWidget() && getListLayout() === LIST_LAYOUT.LIST

  const isListImageEnabled = () => get(stylesParams.listShowImage)

  const isListImageLayoutRight = () => get(stylesParams.listImageLayout) === LIST_IMAGE_LAYOUT.RIGHT

  const isFitImage = () => get(stylesParams.imageScaling) === IMAGE_SCALING.FIT

  const getMultiImageRatio = () => {
    if (get(stylesParams.multiImageRatio)) {
      return get(stylesParams.multiImageRatio)
    }

    if (isCardsLayout() && get(stylesParams.imageRatio)) {
      return get(stylesParams.imageRatio)
    }

    return getDefaultMultiImageRatio()
  }

  const getDefaultMultiImageRatio = () => {
    if (isCalendarLayout() || (mobile && !isCardsLayout())) {
      return IMAGE_RATIO['16:9']
    }

    return IMAGE_RATIO['1:1']
  }

  const getMultiImageOpacity = () => {
    if (get(stylesParams.multiImageOpacity) !== undefined) {
      return get(stylesParams.multiImageOpacity)
    }

    if (isCardsLayout() || isSideBySideLayout()) {
      return get(stylesParams.listImageOpacity)
    }

    return getDefaultMultiImageOpacity()
  }

  const getDefaultMultiImageOpacity = () => 100

  const a = {
    getWidgetLayout,
    getMultiImageRatio,
    getDefaultMultiImageRatio,
    getMultiImageOpacity,
    getDefaultMultiImageOpacity,
    getButtonsFontKey,
    isListAdditionalComponentsVisible,
    isSideBySideDateOrLocationEnabled,
    isListDescriptionEnabled,
    isListFullDateOrLocationEnabled,
    isListSocialShareEnabled,
    isListDateOrVenueEnabled,
    isHollowButton,
    isRoundedButton,
    isAnyStatusRibbonEnabled,
    isAnyRibbonEnabled,
    isListCompactLocationAndDateFormat,
    isListMainTitleEnabled,
    isListHollowButton,
    isListRoundedButton,
    isCalendarLayout,
    isTodayButtonHollow,
    isTodayButtonRounded,
    isListVenueNameEnabled,
    isSideBySideLayout,
    isCardsLayout,
    isListLayout,
    isListImageEnabled,
    isListImageLayoutRight,
    isFitImage,
    isMultiEventWidget,
    getWidgetType,
    isListAdditionalInfoEnabled,
    isSoldOutRibbonEnabled,
    isRsvpClosedRibbonEnabled,
    isJoinWaitlistRibbonEnabled,
    isOfferRibbonEnabled,
    isRecurringRibbonEnabled,
    isListDateEnabled,
  }

  console.log(a)

  return {
    cardsImageRatio: getMultiImageRatio() === IMAGE_RATIO['16:9'] ? '56.25%' : '100%',
    CARD_MAX_WIDTH: '500',
    CARD_MIN_WIDTH: '200',
  }
}
